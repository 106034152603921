html {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overscroll-behavior-x: none;
  touch-action: none;
}

body {
  color : #333;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
  touch-action: none;

  font-size: 16px;
  line-height: 1.4em;

 
  margin: 0;
  padding: 0;
  
  width: 100vw;
  height: 100vh;

}

.App {
  width: 100vw;
  height: 100vh;
}
